export default function flashNotification(title, message) {
    const notification = {
        title,
        message,
        severity: 'temporary',
    };

    const notifications = JSON.parse(window.sessionStorage.getItem('notifications') || '[]');

    notifications.push(notification);

    window.sessionStorage.setItem('notifications', JSON.stringify(notifications));
}
