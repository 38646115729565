import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Minutemailer from '@app/Minutemailer';
import ViewLoading from '@components/ViewLoading';
import { MinutemailerUI } from '@minutemailer/ui';
import useLogging from '@hooks/useLogging';
import useI18n from '@lib/useI18n';

const lang = document.documentElement.getAttribute('lang').split('_')[0];
const Login = lazy(() => import('@pages/Login.jsx'));
const Register = lazy(() => import('@pages/Register.jsx'));
const ForgotPassword = lazy(() => import('@pages/ForgotPassword.jsx'));
const ResetPassword = lazy(() => import('@pages/ResetPassword.jsx'));
const ConfirmedAddress = lazy(() => import('@pages/ConfirmedAddress.jsx'));
const ExternalNotifications = lazy(() =>
    import('@pages/ExternalNotifications.jsx'),
);
const app = document.getElementById('app');
const appProps = app.getAttribute('data-props');

function External() {
    const t = useI18n('ui');

    useLogging();

    return (
        <Minutemailer.Provider
            value={{ lang, appProps: appProps ? JSON.parse(appProps) : {} }}
        >
            <MinutemailerUI t={t}>
                <BrowserRouter>
                    <Suspense fallback={<ViewLoading />}>
                        <Switch>
                            <Route
                                path={`/${lang}/login`}
                                exact
                                component={Login}
                            />
                            <Route
                                path={`/${lang}/register`}
                                component={Register}
                            />
                            <Route
                                path={`/${lang}/password/remind`}
                                exact
                                component={ForgotPassword}
                            />
                            <Route
                                path={`/${lang}/password/reset/:token`}
                                exact
                                component={ResetPassword}
                            />
                            <Route
                                path="/fromaddress/confirm/:user/:token"
                                exact
                                component={ConfirmedAddress}
                            />
                            <Route
                                path={`/${lang}/settings/notifications/:token`}
                                exact
                                component={ExternalNotifications}
                            />
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </MinutemailerUI>
        </Minutemailer.Provider>
    );
}

const root = createRoot(document.getElementById('app'));

root.render(<External />);
