import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@minutemailer/ui';
import { Spinner } from '@minutemailer/ui/ui/icons';

export default function ViewLoading({ delay, ...props }) {
    const [display, setDisplay] = useState(!delay);

    useEffect(() => {
        const timer = setTimeout(() => setDisplay(true), 250);

        return function cleanup() {
            clearTimeout(timer);
        };
    }, []);

    if (!display) {
        return null;
    }

    return (
        <Box fontSize="xxl" flex align="center" justify="center" {...props}>
            <Spinner />
        </Box>
    );
}

ViewLoading.defaultProps = {
    delay: true,
};

ViewLoading.propTypes = {
    delay: PropTypes.bool,
};
