import { choice, replacePlaceholders } from '@minutemailer/utils';

export default function i18n(path) {
    return (str, placeholders = {}, value = null) =>
        choice(
            replacePlaceholders(
                path in I18N[window.minutemailerLang] &&
                    str in I18N[window.minutemailerLang][path]
                    ? I18N[window.minutemailerLang][path][str]
                    : str,
                placeholders,
            ),
            value,
        );
}
