import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { useEffect } from 'react';
import * as React from 'react';
import useApp from '@hooks/useApp';

export default function useLogging() {
    const app = useApp();

    useEffect(() => {
        if (app) {
            Bugsnag.start({
                apiKey: process.env.BUGSNAG_JS_API_KEY,
                appVersion: process.env.RELEASE_VERSION,
                enabledReleaseStages: ['stage', 'production'],
                releaseStage: process.env.APP_ENV,
                user: {
                    id: app.user.token,
                    email: app.user.email,
                    name: app.user.name,
                },
                plugins: [new BugsnagPluginReact(React)],
            });

            if ('$crisp' in window) {
                window.$crisp.push(['set', 'user:email', [app.user.email]]);
                window.$crisp.push(['set', 'user:nickname', [app.user.name]]);
                window.$crisp.push(['set', 'user:company', [app.team.name]]);
            }
        }
    }, [app]);

    return Bugsnag;
}
